'use client'

import { useState } from 'react'
import { BriefStep } from '@/components/molecules/iteration-details/CreateIteration/BriefStep'
import { IterationFormData } from '@/components/molecules/iteration-details/CreateIteration/types'
import { PromptStep } from '@/components/molecules/iteration-details/CreateIteration/PromptStep'
import { FormStep } from '@/components/molecules/iteration-details/CreateIteration/FormStep'

export default function MCreateIterationWizard({
  onCreateIteration,
  initialPrompt,
}: {
  onCreateIteration: (any) => void
  initialPrompt?: string
}) {
  const [step, setStep] = useState<'form' | 'prompt' | 'brief'>('form')
  const [formValues, setFormValues] = useState<IterationFormData>({
    prospectName: '',
    prospectWebsite: '',
    projectTemplate: null,
    technologies: null,
    whatToBuild: '',
    technicalRequirements: '',
    prospectRequirements: '',
  })
  const [formIsValid, setFormIsValid] = useState(false)
  const [prompt, setPrompt] = useState(initialPrompt ?? 'Write a hello world app in javascript')

  const handleFormSubmit = (data: IterationFormData, isValid: boolean) => {
    setFormValues(data)
    setFormIsValid(isValid)
    setStep('brief')
  }

  const handlePromptChange = (newPrompt: string) => {
    setPrompt(newPrompt)
  }

  const handleSubmit = () => {
    console.log({ ...formValues, prompt })
    onCreateIteration({
      configuration: { ...formValues },
      prompt,
    })
    // Handle final submission here
  }

  const handleSeePrompt = () => {
    setStep('prompt')
  }

  return (
    <>
      {step === 'form' && (
        <FormStep
          onSubmit={handleFormSubmit}
          initialValues={formValues}
          onSeePrompt={handleSeePrompt}
        />
      )}
      {step === 'prompt' && (
        <PromptStep
          prompt={prompt}
          onPromptChange={handlePromptChange}
          onBack={() => setStep('form')}
          onNext={() => setStep('brief')}
          formIsValid={formIsValid}
        />
      )}
      {step === 'brief' && (
        <BriefStep
          formValues={formValues}
          prompt={prompt}
          onBack={() => setStep('form')}
          onSubmit={handleSubmit}
        />
      )}
    </>
  )
}
