import { Options } from '@/components/molecules/iteration-details/CreateIteration/types'

export const technologiesOptions: Options = [
  { value: 'shopify-hydrogen', label: 'Shopify Hydrogen', group: 'Commerce Engine' },
  { value: 'commerce-layer', label: 'Commerce Layer', group: 'Commerce Engine' },
]
export const projectTemplates: {
  key: string
  name: string
  description: string
  technologies: Options
  whatToBuild: string
  technicalRequirements: string
  prospectRequirements: string
}[] = [
  {
    key: 'demo-store',
    name: 'Demo Store',
    description: 'Around 10 products',
    technologies: [
      { value: 'shopify-hydrogen', label: 'Shopify Hydrogen', group: 'Commerce Engine' },
      { value: 'react', label: 'React', group: 'Frontend' },
      { value: 'contentful', label: 'Contentful', group: 'CMS' },
    ],
    whatToBuild: 'A small e-commerce store with basic product listing and checkout functionality.',
    technicalRequirements: 'Shopify API integration, responsive design, basic SEO',
    prospectRequirements: 'Easy to manage inventory, simple checkout process',
  },
  {
    key: 'complex-shop',
    name: 'Complex Shop',
    description: '5+ categories',
    technologies: [
      { value: 'angular', label: 'Angular', group: 'Frontend' },
      { value: 'commercetools', label: 'Commercetools', group: 'Commerce Engine' },
      { value: 'sanity', label: 'Sanity', group: 'CMS' },
    ],
    whatToBuild: 'A multi-category e-commerce platform with advanced search and filtering.',
    technicalRequirements:
      'Server-side rendering, GraphQL API, Stripe payment integration, advanced product filtering',
    prospectRequirements: 'Multiple product categories, user accounts, wish lists',
  },
  {
    key: 'enterprise',
    name: 'Enterprise',
    description: '3+ stores',
    technologies: [
      { value: 'vue', label: 'Vue', group: 'Frontend' },
      { value: 'shopware', label: 'Shopware', group: 'Commerce Engine' },
      { value: 'strapi', label: 'Strapi', group: 'CMS' },
    ],
    whatToBuild: 'A scalable multi-store e-commerce ecosystem with shared backend services.',
    technicalRequirements:
      'Microservices architecture, Kubernetes deployment, shared authentication service, data synchronization between stores',
    prospectRequirements:
      'Multiple storefronts, centralized inventory management, cross-store analytics',
  },
]
export const helpContentWithExamples = {
  prospectName: {
    text: "Enter the name of the prospect or company you're creating this project for.",
    examples: [],
  },
  prospectWebsite: {
    text: 'Enter the website URL of the prospect. This helps in understanding their current online presence.',
    examples: [],
  },
  projectTemplate: {
    text: "Select a pre-defined project template that best fits your prospect's needs. This will pre-fill some fields with suggested content.",
    examples: [],
  },
  technologies: {
    text: 'List the main technologies, frameworks, or platforms you plan to use for this project.',
    examples: ['React, Node.js, MongoDB', 'Vue.js, Express, PostgreSQL', 'Angular, Django, MySQL'],
  },
  whatToBuild: {
    text: 'Describe the main features and functionality of the project you want to build.',
    examples: [
      'A responsive e-commerce website with user authentication and payment integration',
      'A real-time chat application with video calling capabilities',
      'A project management tool with task tracking and team collaboration features',
    ],
  },
  technicalRequirements: {
    text: 'List any specific technical requirements or constraints for the project.',
    examples: [
      'RESTful API, responsive design, OAuth 2.0 authentication',
      'WebSocket for real-time updates, cloud storage integration, mobile-first approach',
      'GraphQL API, containerized microservices, CI/CD pipeline',
    ],
  },
  prospectRequirements: {
    text: 'Note any specific requirements or preferences mentioned by the prospect.',
    examples: [
      'User-friendly interface, fast loading times, secure payment processing',
      'Cross-platform compatibility, offline mode support, end-to-end encryption',
      'Customizable dashboards, role-based access control, detailed analytics',
    ],
  },
}
