import { useState } from 'react'
import { Textarea } from '@/components/ui/textarea'
import { Button } from '@/components/ui/button'
import { ArrowLeft, ArrowRight } from 'lucide-react'
import { HelpContent } from '@/components/molecules/iteration-details/CreateIteration/HelpContent'

export const PromptStep = ({
  prompt,
  onPromptChange,
  onBack,
  onNext,
  formIsValid,
}: {
  prompt: string
  onPromptChange: (prompt: string) => void
  onBack: () => void
  onNext: () => void
  formIsValid: boolean
}) => {
  const [selectedField, setSelectedField] = useState<string | null>(null)

  const handleExampleClick = (field: string, value: string) => {
    onPromptChange(prompt + '\n' + value)
  }

  return (
    <div className="flex h-full">
      <div className="flex w-3/5 flex-col p-6">
        <h2 className="mb-4 text-2xl font-bold">Prompt preview</h2>
        <Textarea
          value={prompt}
          onChange={e => onPromptChange(e.target.value)}
          placeholder="Edit or add more to your prompt..."
          className="flex-grow resize-none overflow-y-auto border-0 !border-none outline-none focus:border-0 focus:!border-none focus:outline-none focus:ring-0"
          onFocus={() => setSelectedField('prompt')}
          rows={25}
        />
        <div className="mt-6 flex items-center justify-between border-t pt-4">
          <Button
            type="button"
            onClick={onBack}
            className="border border-gray-300 bg-white text-gray-700 hover:bg-gray-50"
          >
            <ArrowLeft className="mr-2 h-4 w-4" /> Back
          </Button>
          <Button
            type="button"
            onClick={onNext}
            className="bg-blue-600 text-white hover:bg-blue-700"
            disabled={!formIsValid}
          >
            Next <ArrowRight className="ml-2 h-4 w-4" />
          </Button>
        </div>
      </div>
      <div className="w-2/5 bg-gray-100">
        <HelpContent selectedField={selectedField} onExampleClick={handleExampleClick} />
      </div>
    </div>
  )
}
