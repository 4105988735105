import * as z from 'zod'

const optionsSchema = z.array(z.object({ value: z.string(), label: z.string(), group: z.string() }))

export type Options = z.infer<typeof optionsSchema>
export const iterationFormData = z.object({
  prospectName: z.string().min(1, { message: 'Prospect Name is required' }),
  prospectWebsite: z.string().min(1, { message: 'Prospect Website is required' }),
  // projectTemplate: z.string().nullable(),
  technologies: z.string().min(1, { message: 'Technologies are required' }),
  // whatToBuild: z.string().min(1, { message: 'What to build is required' }),
  // technicalRequirements: z.string(),
  // prospectRequirements: z.string(),
})
export type IterationFormData = z.infer<typeof iterationFormData>
