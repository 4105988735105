const repositoryDefaultFields = [
  {
    name: 'Repository URI',
    key: 'repoURI',
    requiresDefaultValue: false,
    hidden: false,
    description: 'Log into the GitHub repository and copy the URI. git@github.com:org/repo.git',
    type: 'text-short',
    placeholder: 'git@github.com:org/repo.git',
    dontEncrypt: true,
  },
  {
    name: 'Start commit hash',
    key: 'startCommitHash',
    requiresDefaultValue: false,
    hidden: false,
    description:
      'The commit hash where the iteration starts. You can get this from the GitHub repository. Looks like a key of numbers and letters.',
    type: 'text-short',
    placeholder: '1234567890abcdef',
    dontEncrypt: true,
  },
  {
    name: 'GitHub PAT (Personal Access Token)',
    key: 'githubKey',
    requiresDefaultValue: false,
    hidden: false,
    description:
      'A GitHub PAT created by going to https://github.com/settings/tokens?type=beta and clicking "Generate new token". It requires Read and Write access to the Code, and optionally Read and Write access to Pull Requests.',
    type: 'text-short',
    placeholder: '-----BEGIN....',
    dontEncrypt: false,
  },
]

export { repositoryDefaultFields }
