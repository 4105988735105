import {
  projectTemplates,
  technologiesOptions,
} from '@/components/molecules/iteration-details/CreateIteration/mockData'
import {
  IterationFormData,
  iterationFormData,
  Options,
} from '@/components/molecules/iteration-details/CreateIteration/types'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'
import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectValue,
  SelectItem,
} from '@/components/ui/select'
import MultipleSelector from '@/components/ui/MultipleSelector'
import { Textarea } from '@/components/ui/textarea'
import { Button } from '@/components/ui/button'
import { ArrowRight, Eye, Plus, X } from 'lucide-react'
import { HelpContent } from '@/components/molecules/iteration-details/CreateIteration/HelpContent'
import { ProjectTemplateCard } from '@/components/molecules/iteration-details/CreateIteration/ProjectTemplateCard'

export const FormStep = ({
  onSubmit,
  initialValues,
  onSeePrompt,
}: {
  onSubmit: (data: IterationFormData, isValid: boolean) => void
  initialValues: Partial<IterationFormData>
  onSeePrompt: () => void
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors, isValid: formIsValid },
  } = useForm<IterationFormData>({
    resolver: zodResolver(iterationFormData),
    defaultValues: initialValues,

    mode: 'onChange',
  })

  console.log('formIsValid: ', formIsValid)
  console.log('errors: ', errors)

  const [showTechnicalReq, setShowTechnicalReq] = useState(!!initialValues.technicalRequirements)
  const [showProspectReq, setShowProspectReq] = useState(!!initialValues.prospectRequirements)
  const [selectedField, setSelectedField] = useState<string | null>(null)
  const selectedTemplate = watch('projectTemplate')

  // const handleTemplateClick = (templateKey: string) => {
  //   if (selectedTemplate === templateKey) {
  //     setValue('projectTemplate', null, { shouldValidate: true })
  //     setValue('technologies', [], { shouldValidate: true })
  //     setValue('whatToBuild', '', { shouldValidate: true })
  //     setValue('technicalRequirements', '', { shouldValidate: true })
  //     setValue('prospectRequirements', '', { shouldValidate: true })
  //     setShowTechnicalReq(false)
  //     setShowProspectReq(false)
  //   } else {
  //     setValue('projectTemplate', templateKey, { shouldValidate: true })
  //     const template = projectTemplates.find(t => t.key === templateKey)
  //     if (template) {
  //       // ts-ignore
  //       setValue('technologies', template.technologies as IterationFormData['technologies'], {
  //         shouldValidate: true,
  //       })
  //       setValue('whatToBuild', template.whatToBuild, { shouldValidate: true })
  //       setValue('technicalRequirements', template.technicalRequirements, { shouldValidate: true })
  //       setValue('prospectRequirements', template.prospectRequirements, { shouldValidate: true })
  //       setShowTechnicalReq(true)
  //       setShowProspectReq(true)
  //     }
  //   }
  // }

  const handleSeePromptClick = () => {
    onSubmit(getValues(), formIsValid)
    onSeePrompt()
  }

  const handleExampleClick = (field: string, value: string) => {
    setValue(field as keyof IterationFormData, value, { shouldValidate: true })
  }

  const handleFormSubmit = (data: IterationFormData) => {
    onSubmit(data, formIsValid)
  }

  return (
    <div className="flex h-full w-full ">
      <div className="flex w-3/5 flex-shrink-0 flex-col  overflow-x-auto  p-6">
        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          className="flex-grow space-y-6 overflow-y-auto"
        >
          <div className="grid grid-cols-2 gap-4">
            <div>
              <Label htmlFor="prospectName" className="text-sm font-medium text-gray-700">
                Prospect Name
              </Label>
              <Input
                id="prospectName"
                {...register('prospectName')}
                className="mt-1"
                onFocus={() => setSelectedField('prospectName')}
              />
              {errors.prospectName && (
                <p className="mt-1 text-sm text-red-500">{errors.prospectName.message}</p>
              )}
            </div>
            <div>
              <Label htmlFor="prospectWebsite" className="text-sm font-medium text-gray-700">
                Prospect Website
              </Label>
              <Input
                id="prospectWebsite"
                {...register('prospectWebsite')}
                className="mt-1"
                onFocus={() => setSelectedField('prospectWebsite')}
              />
              {errors.prospectWebsite && (
                <p className="mt-1 text-sm text-red-500">{errors.prospectWebsite.message}</p>
              )}
            </div>
          </div>
          {/*<div>*/}
          {/*  <Label className="mb-2 block text-sm font-medium text-gray-700">Project Template</Label>*/}
          {/*  <div*/}
          {/*    className="flex flex-wrap gap-4"*/}
          {/*    onClick={() => setSelectedField('projectTemplate')}*/}
          {/*  >*/}
          {/*    {projectTemplates.map(template => (*/}
          {/*      <ProjectTemplateCard*/}
          {/*        key={template.key}*/}
          {/*        template={template}*/}
          {/*        selected={selectedTemplate === template.key}*/}
          {/*        onClick={() => handleTemplateClick(template.key)}*/}
          {/*      />*/}
          {/*    ))}*/}
          {/*  </div>*/}
          {/*</div>*/}
          <Controller
            name="technologies"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <div>
                <Select onValueChange={field.onChange} defaultValue={field.value}>
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select a technology..." />
                  </SelectTrigger>
                  <SelectContent>
                    {technologiesOptions.map(option => (
                      <SelectItem key={option.value} value={option.value}>
                        {option.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>

                {error && <p className="text-red-500">{error.message}</p>}
              </div>
            )}
          />

          {/*<div>*/}
          {/*  <Label htmlFor="whatToBuild" className="text-sm font-medium text-gray-700">*/}
          {/*    What do you want to build?*/}
          {/*  </Label>*/}
          {/*  <Textarea*/}
          {/*    id="whatToBuild"*/}
          {/*    {...register('whatToBuild')}*/}
          {/*    className="mt-1"*/}
          {/*    onFocus={() => setSelectedField('whatToBuild')}*/}
          {/*  />*/}
          {/*  {errors.whatToBuild && (*/}
          {/*    <p className="mt-1 text-sm text-red-500">{errors.whatToBuild.message}</p>*/}
          {/*  )}*/}
          {/*</div>*/}
          {/*<div className="flex space-x-4">*/}
          {/*  {!showTechnicalReq && (*/}
          {/*    <Button*/}
          {/*      type="button"*/}
          {/*      variant="outline"*/}
          {/*      onClick={() => setShowTechnicalReq(true)}*/}
          {/*      className="border-gray-300 text-gray-700 hover:bg-gray-50"*/}
          {/*    >*/}
          {/*      <Plus className="mr-2 h-4 w-4" /> Technical Requirements*/}
          {/*    </Button>*/}
          {/*  )}*/}
          {/*  {!showProspectReq && (*/}
          {/*    <Button*/}
          {/*      type="button"*/}
          {/*      variant="outline"*/}
          {/*      onClick={() => setShowProspectReq(true)}*/}
          {/*      className="border-gray-300 text-gray-700 hover:bg-gray-50"*/}
          {/*    >*/}
          {/*      <Plus className="mr-2 h-4 w-4" /> Prospect Requirements*/}
          {/*    </Button>*/}
          {/*  )}*/}
          {/*</div>*/}
          {/*{showTechnicalReq && (*/}
          {/*  <div className="mt-4">*/}
          {/*    <div className="mb-2 flex items-center justify-between">*/}
          {/*      <Label htmlFor="technicalRequirements">Technical Requirements</Label>*/}
          {/*      <Button*/}
          {/*        type="button"*/}
          {/*        variant="ghost"*/}
          {/*        size="sm"*/}
          {/*        onClick={() => {*/}
          {/*          setValue('technicalRequirements', '')*/}
          {/*          setShowTechnicalReq(false)*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        <X className="h-4 w-4" />*/}
          {/*      </Button>*/}
          {/*    </div>*/}
          {/*    <Textarea*/}
          {/*      id="technicalRequirements"*/}
          {/*      {...register('technicalRequirements')}*/}
          {/*      className="w-full"*/}
          {/*      rows={4}*/}
          {/*      onFocus={() => setSelectedField('technicalRequirements')}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*)}*/}
          {/*{showProspectReq && (*/}
          {/*  <div className="mt-4">*/}
          {/*    <div className="mb-2 flex items-center justify-between">*/}
          {/*      <Label htmlFor="prospectRequirements">Prospect Requirements</Label>*/}
          {/*      <Button*/}
          {/*        type="button"*/}
          {/*        variant="ghost"*/}
          {/*        size="sm"*/}
          {/*        onClick={() => {*/}
          {/*          setValue('prospectRequirements', '')*/}
          {/*          setShowProspectReq(false)*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        <X className="h-4 w-4" />*/}
          {/*      </Button>*/}
          {/*    </div>*/}
          {/*    <Textarea*/}
          {/*      id="prospectRequirements"*/}
          {/*      {...register('prospectRequirements')}*/}
          {/*      className="w-full"*/}
          {/*      rows={4}*/}
          {/*      onFocus={() => setSelectedField('prospectRequirements')}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*)}*/}
          <div className="mt-6 flex items-center justify-between">
            <Button
              type="button"
              onClick={handleSeePromptClick}
              className="bg-gray-200 text-gray-700 hover:bg-gray-300"
            >
              <Eye className="mr-2 h-4 w-4" /> See prompt
            </Button>
            <Button
              type="submit"
              disabled={!formIsValid}
              className="bg-blue-600 text-white hover:bg-blue-700"
            >
              Next <ArrowRight className="ml-2 h-4 w-4" />
            </Button>
          </div>
        </form>
      </div>
      <div className="w-2/5 max-w-[450px] flex-shrink-0 flex-col overflow-x-auto bg-gray-100">
        <HelpContent selectedField={selectedField} onExampleClick={handleExampleClick} />
      </div>
    </div>
  )
}
