import { useEffect, useMemo, useRef, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Link, Navigate, Route, Routes, ScrollRestoration, useLocation } from 'react-router-dom'
import useStore from '@/stores/useStore'

import { auth } from '@/services/Firebase'
import { ROUTES } from '@/const/routes'

import PLoginWithEmailLinkConfirmation from '@/components/pages/PLoginWithEmailLinkConfirmation'
import PLogin from '@/components/pages/PLogin'

import PHome from '@/components/pages/PHome'
import PRojectDetails from '@/components/pages/PProjectDetails'
import POrganizationDetails from '@/components/pages/POrganizationDetails'
import PTeamDetails from '@/components/pages/PTeamDetails'
import PManageVMs from '@/components/pages/PManageVMs'
import PIterationFeedback from './components/pages/PIterationFeedback'
import {
  ANALYTIC_EVENTS,
  analyticsIdentify,
  analyticsIsOptedInCapturing,
  analyticsOpInCapturing,
  analyticsOptOutCapturing,
  analyticsTrackEvent,
} from './services/Analytics'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { cn } from '@/lib/utils'
import {
  Building2,
  ChevronRight,
  FolderKanban,
  LayoutDashboard,
  Search,
  Settings,
  User,
  Users,
  Zap,
} from 'lucide-react'

function LoginRouter() {
  return (
    <>
      <Routes>
        <Route path={ROUTES.ROOT} element={<PLogin />} />
        <Route
          exact
          path={ROUTES.SIGN_IN_WITH_LINK_CONFIRMATION}
          element={<PLoginWithEmailLinkConfirmation />}
        />

        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
      <ScrollRestoration />
    </>
  )
}

function MMainAnalytics() {
  const [authUser] = useAuthState(auth)
  const appOpenEventSent = useRef(false)
  const organizations = useStore(state => state.organizations)
  const user = useStore(state => state.user)
  const userRoles = useStore(state => state.userRoles)
  const location = useLocation()

  useEffect(() => {
    if (authUser?.uid && user && organizations.length > 0 && userRoles) {
      const isExternal = user?.isExternal ? true : false

      // if user is external, we need to track the user
      // if user is internal, we need to opt out of tracking
      if (isExternal) {
        if (!analyticsIsOptedInCapturing()) {
          analyticsOpInCapturing()
        }

        const orgIds = organizations.map(org => org.id)
        analyticsIdentify(authUser?.uid, {
          name: `${user?.firstName} ${user?.lastName}`,
          userId: authUser?.uid,
          isExternal,
          organizations: orgIds,
        })
        if (!appOpenEventSent.current) {
          analyticsTrackEvent(ANALYTIC_EVENTS.APP_OPEN)
          appOpenEventSent.current = true
        }
      } else {
        if (analyticsIsOptedInCapturing()) {
          analyticsOptOutCapturing()
        }
      }
    }
  }, [user, organizations, userRoles, authUser?.uid])

  useEffect(() => {
    analyticsTrackEvent('$pageview')
  }, [location])

  return null
}

function App() {
  const [authUser, isAuthUserLoading] = useAuthState(auth)
  const loadInitialData = useStore(state => state.loadInitialData)

  const isUserLoggedOut = useMemo(() => {
    return Boolean(!authUser && !isAuthUserLoading)
  }, [authUser, isAuthUserLoading])

  // Load initial data when auth is ready and user is logged in
  // Unsubscribe from all listeners when component is unmounted
  useEffect(() => {
    if (authUser?.uid) {
      loadInitialData(authUser.uid)
    }
    return () => {
      useStore.getState().unsubscribeFromAllListeners()
    }
  }, [authUser?.uid, loadInitialData])

  if (isAuthUserLoading) {
    return null
  }

  if (isUserLoggedOut) {
    return <LoginRouter />
  }
  return (
    <Layout>
      <Routes>
        <Route path={ROUTES.SIGN_IN_WITH_LINK_CONFIRMATION} element={<Navigate replace to="/" />} />
        <Route path={ROUTES.HOME} exact element={<PHome />} />
        <Route path={ROUTES.PROJECT_DETAILS} element={<PRojectDetails />} />
        <Route path={ROUTES.ORGANIZATION_DETAILS} element={<POrganizationDetails />} />
        <Route path={ROUTES.TEAM_DETAILS} element={<PTeamDetails />} />
        <Route path={ROUTES.MANAGE_VMS} element={<PManageVMs />} />
        <Route path={ROUTES.ITERATION_FEEDBACK} element={<PIterationFeedback />} />
        <Route path="*" element={<Navigate replace to={ROUTES.HOME} />} />
      </Routes>
      {/*<ScrollRestoration />*/}
      <MMainAnalytics />
    </Layout>
  )
}

/*

Our routes:
- Home
- Project details
- Organization details
- Team details
- Manage VMs
- Iteration feedback

Figma routes:
- Dashboard
- Projects
- Team
- Directory

- Integrations
- Settings

 */

function Sidebar({ isExpanded, setIsExpanded }) {
  const location = useLocation()

  const toggleSidebar = () => setIsExpanded(!isExpanded)

  const menuItems = [
    { icon: LayoutDashboard, label: 'Dashboard', path: ROUTES.HOME },
    { icon: FolderKanban, label: 'Projects', path: ROUTES.PROJECT_DETAILS },
    { icon: Users, label: 'Team', path: ROUTES.TEAM_DETAILS },
    { icon: Building2, label: 'Organization', path: ROUTES.ORGANIZATION_DETAILS },
    { icon: Zap, label: 'Manage VMs', path: ROUTES.MANAGE_VMS },
    { icon: Settings, label: 'Iteration Feedback', path: ROUTES.ITERATION_FEEDBACK },
  ]

  return (
    <aside
      className={cn(
        'flex flex-col bg-gray-100 text-gray-800 transition-all duration-300',
        isExpanded ? 'w-64 p-4' : 'w-16 p-2'
      )}
    >
      <div className="mb-6 flex items-center justify-center">
        {isExpanded ? (
          <>
            <h1 className="text-xl font-semibold">proofs</h1>
            <div className="ml-auto">
              <Search className="h-5 w-5 text-gray-500" />
            </div>
          </>
        ) : (
          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-300">
            <span className="font-bold text-gray-600">P</span>
          </div>
        )}
      </div>
      {isExpanded && (
        <div className="mb-6">
          <Input type="text" placeholder="Search" className="w-full" />
        </div>
      )}
      <nav className="flex-grow">
        <ul className="space-y-2">
          {menuItems.map((item, index) => (
            <li key={index}>
              <Button
                variant="ghost"
                className={cn(
                  'w-full justify-center text-gray-600 hover:bg-gray-200 hover:text-gray-900',
                  isExpanded ? 'justify-start px-4' : 'px-2',
                  location.pathname === item.path && 'relative bg-red-100 text-red-600'
                )}
                asChild
              >
                <Link to={item.path}>
                  {location.pathname === item.path && (
                    <div className="absolute bottom-0 left-0 top-0 w-1 bg-red-600" />
                  )}
                  <item.icon className="h-5 w-5 flex-shrink-0" />
                  {isExpanded && <span className="ml-2">{item.label}</span>}
                </Link>
              </Button>
            </li>
          ))}
        </ul>
      </nav>
      <div className="mt-auto space-y-2">
        <Button
          variant="ghost"
          className={cn(
            'w-full justify-center text-gray-600 hover:bg-gray-200 hover:text-gray-900',
            isExpanded ? 'justify-start px-4' : 'px-2'
          )}
        >
          <User className="h-5 w-5 flex-shrink-0" />
          {isExpanded && <span className="ml-2">Profile</span>}
        </Button>
        <Button
          variant="ghost"
          onClick={toggleSidebar}
          className={cn(
            'w-full text-gray-600 hover:bg-gray-200 hover:text-gray-900',
            isExpanded ? 'justify-start px-4' : 'justify-center px-2'
          )}
        >
          <ChevronRight
            className={cn('h-5 w-5 transition-transform', isExpanded && 'rotate-180')}
          />
          {isExpanded && <span className="ml-2">Expand</span>}
        </Button>
      </div>
    </aside>
  )
}

function Layout({ children }) {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <div className="flex h-screen overflow-hidden bg-white">
      <Sidebar isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
      <main className="flex-1 overflow-y-auto p-8">{children}</main>
    </div>
  )
}

export default App
